var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('orisis-logo-full')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Modifier votre mot passe 🔒 ")]),_c('validation-observer',{ref:"changePasswordForm"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Email","label-for":"forgot-password-email"}},[_c('validation-provider',{attrs:{"name":"Adresse e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-email","state":errors.length > 0 ? false:null,"name":"forgot-password-email","placeholder":"email@domaine.fr","disabled":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Ancien mot de passe","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"OldPassword","vid":"OldPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-old","type":_vm.password0FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-old","placeholder":"············"},model:{value:(_vm.userOldPassword),callback:function ($$v) {_vm.userOldPassword=$$v},expression:"userOldPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password0ToggleIcon},on:{"click":_vm.togglePassword0Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('vs-divider'),_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Nouveau mot de passe","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.userNewPassword),callback:function ($$v) {_vm.userNewPassword=$$v},expression:"userNewPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Doit contenir au moins 8 caractères dont : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial")]):_vm._e()]}}])})],1),_c('b-form-group',{staticClass:"mb-1",attrs:{"label-for":"reset-password-confirm","label":"Confirmer votre mot de passe"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.userConfirmPassword),callback:function ($$v) {_vm.userConfirmPassword=$$v},expression:"userConfirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Confirmation invalide")]):_vm._e()]}}])})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"primary"}},[_vm._v(" Enregistrer mon nouveau mot de passe ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":'/login'}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Retour à la connexion ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }